<script>
import axios from '@/axios';
import Navbar from '../Navbar.vue';

import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
})

export default {
  components: { Navbar },
  data: () => ({
    loading: false,
    position: null,
    description: null,
    location: null,
    email: null,
    nationality: null,
    mobile: null,
    developer_path: '',
    languages: [],
    softwares: [],
    frameworks: [],
  }),
  created() {
    this.fetchAbout();
    this.fetchSkills();
  },
  methods: {
    fetchAbout() {
      this.loading = true;
      axios.get('/display').then(res => {
        this.position = res.data.about.position;
        this.description = res.data.about.description;
        this.location = res.data.about.location;
        this.email = res.data.about.email;
        this.nationality = res.data.about.nationality;
        this.mobile = res.data.about.mobile;

        this.developer_path = res.data.about.developer_path;
        this.loading = false;

      })
    },
    fetchSkills() {
      this.loading = true;
      axios.get('/category_list').then(res => {
        this.languages = res.data.languages;
        this.softwares = res.data.softwares;
        this.frameworks = res.data.frameworks;
        this.loading = false;
      })
    },
  },
}
</script>
<template>
  <v-app class="layout">

  
    <Navbar /> 

      <v-container class="mt-15">
      <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
    <div class="p-3">
      
      <div class="flex text-white bg-blue-700">
        <div class="me-3 shrink-0">
          <img class="w-40 h-40 rounded-full mt-3" :src="developer_path" alt="Developer logo">
        </div>
        <div class="mt-5">
          <p class="mb-5 text-base font-semibold leading-none text-gray-900 dark:text-white">
          <h1 class="text-xl font-semibold text-white dark:text-white">{{position}}</h1>
          </p>
          <p class="mb-3 pa-1 text-left rtl:text-right text-white dark:text-white">
           {{ description}} </p>

        </div>



      </div>

      <div class="mt-10">



        <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">

          <li class="flex items-center space-x-3 rtl:space-x-reverse">

            <div id="toast-simple" class="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
                <svg class="w-6 h-6 text-blue-500 dark:text-blue-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                  d="M12 2a8 8 0 0 1 6.6 12.6l-.1.1-.6.7-5.1 6.2a1 1 0 0 1-1.6 0L6 15.3l-.3-.4-.2-.2v-.2A8 8 0 0 1 11.8 2Zm3 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  clip-rule="evenodd" />
                </svg>
                <div class="ps-4 max-w-lg text-md font-medium leading-relaxed text-gray-900 dark:text-white">{{location}}</div>
            </div>



          </li>
          
          <li class="flex items-center space-x-3 rtl:space-x-reverse">

            <div id="toast-simple" class="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
              <svg class="w-6 h-6 text-blue-500 dark:text-blue-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M2 5.6V18c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V5.6l-.9.7-7.9 6a2 2 0 0 1-2.4 0l-8-6-.8-.7Z"/>
                <path d="M20.7 4.1A2 2 0 0 0 20 4H4a2 2 0 0 0-.6.1l.7.6 7.9 6 7.9-6 .8-.6Z"/>
              </svg>
                <div class="ps-4 max-w-lg text-md font-medium leading-relaxed text-gray-900 dark:text-white">{{email}}</div>
            </div>

          </li>
          <li class="flex items-center space-x-3 rtl:space-x-reverse">

            <div id="toast-simple" class="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
              <svg class="w-6 h-6 text-blue-500 dark:text-blue-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 14v7M5 5v9.5c5.6-5.5 8.4 2.7 14 0V4.8c-5.6 2.7-8.4-5.5-14 0Z"/>
              </svg>
                <div class="ps-4 max-w-lg text-md font-medium leading-relaxed text-gray-900 dark:text-white">{{nationality}}</div>
            </div>
          </li>
          <li class="flex items-center space-x-3 rtl:space-x-reverse">

            <div id="toast-simple" class="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
 

            <svg class="w-6 h-6 text-blue-500 dark:text-blue-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M5 4c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4Zm12 12V5H7v11h10Zm-5 1a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clip-rule="evenodd"/>
            </svg> 
      
  
                <div class="ps-4 max-w-lg text-md font-medium leading-relaxed text-gray-900 dark:text-white">{{mobile}}</div>
            </div>
          </li>
        </ul>
      </div>

      

    </div>

      <div>

        <div class="p-6 mt-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Language</h5>
          <div class="grid grid-cols-2 gap-2 sm:grid-cols-4">
            <p class="mb-3 mr-7 mb font-normal text-green-500 dark:text-green-400" v-for="item in languages" :key="item.id">
              <span><i id="fa-icons" :class="item.icon"></i> {{item.name}}
              </span>
            </p>
          </div>
          
        </div>

        <div class="mt-2 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Software</h5>
          <div class="grid grid-cols-2 gap-2 sm:grid-cols-4">
            <p class="mb-3 mr-7 mb font-normal text-violet-500 dark:text-violet-400" v-for="item in softwares" :key="item.id">
              <span><i id="fa-icons" :class="item.icon"></i> {{item.name}}
              </span>
            </p>
          </div>

        </div>

        <div class="mt-2 p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Framework</h5>

            <div class="grid grid-cols-2 gap-2 sm:grid-cols-4">
              <p class="mb-3 mr-7 mb font-normal text-teal-500 dark:text-teal-400" v-for="item in frameworks" :key="item.id">
              <span><i id="fa-icons" :class="item.icon"></i> {{item.name}}
              </span>
            </p>
          </div>

        </div>

        
      </div>

  </div>
</v-container>

    </v-app>
</template>
  


<style scoped>
.layout{
  background-color: #edf4f8;
  font-family: 'Rubik', sans-serif;
}
#fa-icons {
  font-size: 22px;
}

</style>

