<script>
import axios from "@/axios";
import AdminHeader from "./AdminHeader.vue";
import Swal from "sweetalert2";
export default {
  components: { AdminHeader },
  data: () => ({
    dialog: false,
    edit: false,
    loading: true,
    about: {
      id: "",
      name: "",
      position: "",
      description: "",
      location: "",
      email: "",
      nationality: "",
      mobile: "",
      url_path:"",
    },
    abouts:[],

    profile_path: null,
    profile_preview:null,
   
    developer_path: null,
    developer_preview:null,

    headers: [
      { title: "Name", key: "name", align: "start" },
      { title: "Position", key: "position", align: "start" },
      { title: "Description", key: "description", align: "start" },
      { title: "Location", key: "location", align: "start" },
      { title: "Email", key: "email", align: "start" },
      { title: "Nationality", key: "nationality", align: "start" },
      { title: "Mobile", key: "mobile", align: "end" },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],
  }),
  created() {
    this.fetchData();
  },
  methods: {
    onChangeProfilePath (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.profile_preview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }

      this.profile_path = event.target.files[0]
    },

    onChangeDeveloperPath (event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.developer_preview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }

      this.developer_path = event.target.files[0]
    },

    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios.delete(`/about/${id}`).then((res) => {
              console.log(res);
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              });

              this.fetchData();
            });
          } catch (error) {
            console.error("An error ocurred:", error);
          }
        }
      });

    },
    async updateData() {
        let payload = new FormData();
        payload.append("profile_path", this.profile_path);
        payload.append("developer_path", this.developer_path);
        payload.append("id", this.about.id);
        payload.append("name", this.about.name);
        payload.append("position", this.about.position);
        payload.append("description", this.about.description);
        payload.append("location", this.about.location);
        payload.append("email", this.about.email);
        payload.append("nationality", this.about.nationality);
        payload.append("mobile", this.about.mobile);
        payload.append("url_path", this.about.url_path);


      try {
        await axios.post('/about_update', payload, {});
        this.fetchData();
        this.resetForm();
        this.dialog = false;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
    editItem(about) {
      this.edit = true;
      this.about.id = about.id;
      this.about.name = about.name;
      this.about.position = about.position;
      this.about.description = about.description;
      this.about.location = about.location;
      this.about.email = about.email;
      this.about.nationality = about.nationality;
      this.about.mobile = about.mobile;
      this.about.url_path = about.url_path;
      this.dialog = true;
    },
    resetForm() {
      this.about.name = "";
      this.about.position = "";
      this.about.description = "";
      this.about.location = "";
      this.about.email = "";
      this.about.nationality = "";
      this.about.mobile = "";
    },
    add() {
      this.edit = false;
      this.resetForm();
      this.dialog = true;
    },
    async addData() {
      try {
        let payload = new FormData();
        payload.append("profile_path", this.profile_path);
        payload.append("developer_path", this.developer_path);
        payload.append("name", this.about.name);
        payload.append("position", this.about.position);
        payload.append("description", this.about.description);
        payload.append("location", this.about.location);
        payload.append("email", this.about.email);
        payload.append("nationality", this.about.nationality);
        payload.append("mobile", this.about.mobile);
        payload.append("url_path", this.about.url_path);

        await axios.post("/about", payload, {});
        this.fetchData();
        this.resetForm();
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },

    async fetchData() {
      try {
        const response = await axios.get("/abouts");
        
        const check = response.data.abouts
        if(check.length !== 0){
           this.profile_preview = response.data.abouts[0].profile_path;
           this.developer_preview = response.data.abouts[0].developer_path;


           this.abouts = response.data.abouts;
        }
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
  },
};
</script>
<template>
  <AdminHeader />

  <v-card class="mx-auto" max-width="100%">
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-chip size="large" color="green" label>About Me</v-chip>
            <v-btn
              @click="add()"
              class="ma-2"
              variant="text"
              icon="mdi-plus-box"
              color="blue-lighten-2"
            ></v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="name"
              placeholder="Placeholder"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-data-table :items="abouts" :headers="headers">
        <template v-slot:item.role="{ item }">
          <router-link :to="`/adminrole/${item.id}/${item.company}`" id="button"
            ><v-icon size="30px" color="green">mdi-plus</v-icon></router-link
          >
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon size="small" class="me-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item.id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>

  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="1024">
      <form
        @submit.prevent="edit ? updateData() : addData()"
        enctype="multipart/form-data"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ edit ? "Edit Data" : "Add Data" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="about.name"
                    label="Name"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="about.position"
                    label="Position"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="about.description"
                    auto-grow
                    label="Description"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="about.location"
                    label="Location"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="about.email"
                    label="Email"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="about.nationality"
                    label="Nationality"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="about.mobile"
                    label="Mobile"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6" v-show="edit">
                  <v-card
                    class="mx-auto"
                    width="400"
                    prepend-icon="mdi-image-area"
                  >
                    <template v-slot:title> Profile Image </template>

                    <v-card-text>
                      <v-row justify="center" class="mx-0">
                        <v-col cols="12" sm="12" md="12">
                          <img style="max-height: 100px" class="mb-5" contain :src="profile_preview"/>

                          <input
                            type="file"
                            class="mb-3"
                            @change="onChangeProfilePath"
                            accept="image/*"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="6" v-show="edit">
                  <v-card
                    class="mx-auto"
                    width="400"
                    prepend-icon="mdi-image-area"
                  >
                    <template v-slot:title> Developer Image </template>

                    <v-card-text>
                      <v-row justify="center" class="mx-0">
                        <v-col cols="12" sm="12" md="12">
                          <img
                            style="max-height: 100px"
                            class="mb-5"
                            contain
                            :src="developer_preview"
                          />
                          <input
                            type="file"
                            class="mb-3"
                            @change="onChangeDeveloperPath"
                            accept="image/*"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-show="edit">
                  <v-text-field
                    v-model="about.url_path"
                    label="URL"
                    hint="https//:www.example.com"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" type="submit">
              {{ edit ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </v-row>
</template>
