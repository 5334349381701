<script>
  export default {
    data: () => ({ 
      value: 1,
      items: [
            { title: 'Home', icon: 'mdi-account-tie',to:'/'},
            { title: 'About Me', icon: 'mdi-information-outline',to:'/aboutme'},
            { title: 'Work Experience', icon: 'mdi-briefcase-variant-outline',to:'/experience'},
            { title: 'Projects', icon: 'mdi-view-dashboard',to:'/projects'},
          ],


     }),

    computed: {
      color () {
        switch (this.value) {
          case 0: return 'blue-grey'
          case 1: return 'teal'
          case 2: return 'brown'
          case 3: return 'indigo'
          default: return 'blue-grey'
        }
      },
    },
  }
</script>

<template>
  
    

    <div id="newsletter-banner" tabindex="-1" class="fixed top-0 start-0 z-50 flex justify-between w-full p-4 border-b">
      <v-bottom-navigation class="sticky" v-model="value" bg-color="#1565C0" mode="shift">
      <template v-for="item in items" >
          <v-btn id="button" :to="item.to" ><span>{{item.title}}</span><v-icon>{{item.icon}}</v-icon></v-btn>
      </template>
    </v-bottom-navigation>
    </div>
 
</template>

<style scoped>
#button{
    text-decoration: none;
}
.sticky {
position: sticky;
top: 0;
z-index: 2;
}
</style>