<script>
import axios from '@/axios';
import Navbar from '../Navbar.vue';
  export default {
    components: {Navbar},
    data: () => ({
        projects:[],
        outlined: true,
        isChecked: false,
    }),
    created(){
      this.fetchProjects();
    },
    methods:{
      fetchProjects(){
        axios.get('/showprojects').then(res=>{
         this.projects = res.data.projects;
        })
      },
    },
  }
</script>
<template>
<v-app class="layout">
<Navbar/>
<v-container>

  <div class=" mt-15 grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">

      
        
        <div class="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" v-for="project in projects" :key="project.id" >

            <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{{project.title}}</h5>
        
            <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <span>{{project.description}}</span>
              </li>
            </ul>

            <div class="mt-3">
              <div class="grid w-auto grid-cols-2 text-smrounded-lg md:grid-cols-4 dark:bg-gray-700" v-show="!isChecked">
                <p class="mb-3 mr-7 mb font-normal" v-for="framework in project.frameworks" :key="framework.id">
                  <span :class="framework.color">
                    <i id="fa-icons" style="font-size: 40px;" :class="framework.icon"></i>
                  </span>
                </p>
              </div>
            
              <div class="grid grid-cols-2 gap-2 sm:grid-cols-3" v-show="isChecked">

                <div class="mb-3 mr-7 mb font-normal items-center" v-for="framework in project.frameworks" :key="framework.id">
                  <span :class="framework.color">
                    <i :class="framework.icon" style="font-size: 170%;"></i> {{framework.name}}
                  </span>
                </div>

              </div>

          </div>

            <div>
            <a :href="project.link" target="_blank" class=" mt-5 inline-flex font-medium items-center text-blue-600 hover:underline">
            Check it out
            <svg class="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
            </svg>
             </a>
          </div>
          

      </div>

      

    </div>

</v-container>
</v-app>
<footer class="fixed bottom-0 left-0 z-20 w-full p-4 flex items-start justify-start sm:p-6 ">

  <label class="inline-flex items-start mb-5 cursor-pointer">
  <input type="checkbox" v-model="isChecked" @click="!isChecked" class="sr-only peer">
  <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
  </label>

</footer>
</template>

<style scoped>
.button{
  cursor: pointer;
  text-decoration: none;
}

.layout{
  background-color: #edf4f8;
  font-family: 'Rubik', sans-serif;
}
</style>