<script>
import axios from "@/axios";
import AdminHeader from "./AdminHeader.vue";
import Swal from "sweetalert2";
export default {
  components: { AdminHeader },
  data: () => ({
    dialog: false,
    edit: false,
    loading: true,
    skill: {
      id: "",
      name: "",
      icon: "",
      category: "",
    },
    skills: [],
    categoryList: ["Language", "Software", "Framework"],

    headers: [
      { title: "Name", key: "name", align: "start" },
      { title: "Category", key: "category", align: "end" },
      { title: "Icon", key: "icon", align: "end" },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],
  }),
  created() {
    this.fetchSkills();
  },
  methods: {
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios.delete(`/skill/${id}`).then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              });

              this.fetchSkills();
            });
          } catch (error) {
            console.error("An error ocurred:", error);
          }
        }
      });

      // this.$swal('Hello Vue world!!!');
    },
    async editSkill() {
      let payload = this.skill;
      let id = this.skill.id;
      try {
        await axios.put(`/skill/${id}`, payload, {});
        this.fetchSkills();
        this.resetForm();
        this.dialog = false;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
    editItem(skill) {
      this.edit = true;
      this.skill.id = skill.id;
      this.skill.name = skill.name;
      this.skill.icon = skill.icon;
      this.skill.category = skill.category;
      this.dialog = true;
    },
    resetForm() {
      this.skill.name = "";
      this.skill.icon = "";
      this.skill.category = "";
     
    },
    addData() {
      this.edit = false;
      this.resetForm();
      this.dialog = true;
    },
    async addSkill() {
      let payload = this.skill;
      try {
        await axios.post("/skill", payload, {});
        this.fetchSkills();
        //this.resetForm();
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },

    async fetchSkills() {
      try {
        const response = await axios.get("/skills");
        this.skills = response.data.skills;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
  },
};
</script>
<template>
  <AdminHeader />

  <v-card class="mx-auto" max-width="100%">
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-chip size="large" color="green" label>Skills</v-chip>
            <v-btn
              @click="addData()"
              class="ma-2"
              variant="text"
              icon="mdi-plus-box"
              color="blue-lighten-2"
            ></v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="name"
              placeholder="Placeholder"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-data-table :items="skills" :headers="headers">

        <template v-slot:item.actions="{ item }">
          <v-icon size="small" class="me-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item.id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>

  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="1024">
      <v-form @submit.prevent="edit ? editSkill() : addSkill()">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ edit ? "Edit Data" : "Add Data" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="skill.name"
                    label="Name"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="skill.icon"
                    label="Icon"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                    hint="fa-solid fa-code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="skill.category"
                    :items="categoryList"
                    label="Category"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" type="submit">
              {{ edit ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
