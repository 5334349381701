import { createApp } from 'vue';
import App from './App.vue';
import store from './store'; //Vuex store
import router from './router.js'; //Vuex router

const app = createApp(App)


// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import "@mdi/font/css/materialdesignicons.css";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import './index.css';



import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files
import {fa} from 'vuetify/iconsets/fa';
// import {aliases as aliase} from 'vuetify/iconsets/fa';

const vuetify = createVuetify({
  components,
  directives,
  icons:{
    defaultSet: 'mdi',
    aliases,
    sets:{
      mdi,
      fa,
    },
  },
})


app.use(store)
app.use(router)
app.use(vuetify)
app.use(VueSweetalert2)
app.mount('#app');
