<script>
import axios from '@/axios';
export default {
  data: () => ({
      name:'',
      profile_path: null,
      position:'',
  }),
  created(){
    this.fetchAbout();
  },
  methods:{
    fetchAbout(){
      axios.get('/display').then(res=>{
        this.name = res.data.about.name;
        this.profile_path = res.data.about.profile_path;
        this.position = res.data.about.position;

      })
    },
  },
}
</script>
<template>
  <!-- <div class="flex justify-center align-middle">
       
       <div id="container"  class="w-full p-4 text-center bg-blue-500 ">
   
           <div id="card" class="backdrop-blur-sm md:backdrop-blur-lg">
               
               <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                   <div class=" imgBx flex flex-col items-center pb-10"> -->
  <v-app>
    <!-- <div class="flex justify-center align-middle"> -->

    <div class="div-container">
      <div class="div-card">
        <!-- <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"> -->

        <div class="imgBx">
          <img class="h-auto max-w-full" :src="profile_path" alt="Profile Image">
        </div>

        <div class="div-content">
          <div class="div-details">
            <h2> {{name}} <br /><span>{{position}}</span> </h2>

            <ul class="sci">
              <li>
                <v-btn id="button" to="/aboutme" color="#1565C0" outlined textclass="mr-5"> View profile</v-btn>
              </li>
            </ul>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>

    <!-- </div> -->
  </v-app>
</template>

<style scoped>
.div-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Ubuntu", sans-serif !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50% !important;
  background: linear-gradient(
    #1565c0,
    #1565c0 45%,
    #fff 45%,
    #fff 100%
  ) !important;
}

.div-card {
  position: relative;
  width: 300px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(30px) scale(0.5);
  transform-origin: top;
}

.imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.div-content {
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: flex-end;
padding-bottom: 30px;
}
.div-content .div-details {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
}
.div-content .div-details h2 {
color: #444;
font-size: 1.5em;
font-weight: 500;
}
.div-content .div-details h2 span {
font-size: 0.7em;
color: #03a9f4;
font-weight: 400;
 }
.sci {
position: relative;
display: flex;
margin-top: 5px;
}
.sci li {
list-style: none;
margin: 4px;
}
#button {
text-decoration: none;
box-shadow: 0 7px 15px rgba(0,0,0,0.1);
border-top: 1px solid rgba(0,0,0,0.05);
transition: 0.5s;
} 
</style>
