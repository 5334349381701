<template>
  <v-app>

    <div class="layout">
      <router-view />
    </div>

    <footer class="fixed bottom-0 left-0 z-20 w-full">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400"><v-chip id="button" to="/adminaboutme"><v-icon>mdi-cog</v-icon></v-chip></span>
    </footer>

  </v-app>
</template>

<script>
// import Navbar from './components/Navbar.vue'

export default {
  // components: {
  //   Navbar,
  // },
  name: "App",
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
.layout {
  background-color: #edf4f8;
  font-family: "Ubuntu", sans-serif;
}


</style>
