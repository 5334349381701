<script>
import axios from "@/axios";
import AdminHeader from "./AdminHeader.vue";
import Swal from "sweetalert2";
export default {
  components: { AdminHeader },
  data: () => ({
    dialog: false,
    edit: false,
    loading: true,
    project: {
      id: "",
      title: "",
      description: "",
      link: "",
    },
    projects: [],

    headers: [
      { title: "Title", key: "title", align: "start" },
      { title: "Description", key: "description", align: "start" },
      { title: "Link", key: "link", align: "start" },
      { title: "Framework", key: "framework", align: "end", sortable: false },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],
  }),
  created() {
    this.fetchProject();
  },
  methods: {
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios.delete(`/project/${id}`).then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              });

              this.fetchProject();
            });
          } catch (error) {
            console.error("An error ocurred:", error);
          }
        }
      });

      // this.$swal('Hello Vue world!!!');
    },
    async editProject() {
      let payload = this.project;
      let id = this.project.id;
      try {
        await axios.put(`/project/${id}`, payload, {});
        this.fetchProject();
        this.resetForm();
        this.dialog = false;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
    editItem(project) {
      this.edit = true;
      this.project.id = project.id;
      this.project.title = project.title;
      this.project.description = project.description;
      this.project.link = project.link;
      this.dialog = true;
    },
    resetForm() {
      this.project.title = "";
      this.project.description = "";
      this.project.color = "";
      this.project.link = "";
    },
    addData() {
      this.edit = false;
      this.resetForm();
      this.dialog = true;
    },
    async addProject() {
      let payload = this.project;
      console.log(payload);
      try {
        await axios.post("/project", payload, {});
        this.fetchProject();
        this.resetForm();
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },

    async fetchProject() {
      try {
        const response = await axios.get("/projects");
        this.projects = response.data;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
  },
};
</script>
<template>
  <AdminHeader />

  <v-card class="mx-auto" max-width="100%">
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-chip size="large" color="green" label>Projects</v-chip>
            <v-btn
              @click="addData()"
              class="ma-2"
              variant="text"
              icon="mdi-plus-box"
              color="blue-lighten-2"
            ></v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="name"
              placeholder="Placeholder"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-data-table :items="projects" :headers="headers">
        <template v-slot:item.framework ="{ item }">
          <router-link :to="`/adminframework/${item.id}/${item.title}`" id="button"
            ><v-icon size="30px" color="green">mdi-plus</v-icon></router-link
          >
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon size="small" class="me-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item.id)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>

  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="1024">
      <v-form @submit.prevent="edit ? editProject() : addProject()">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ edit ? "Edit Data" : "Add Data" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="project.title"
                    label="Title"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="project.description"
                    label="Description"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                    
                  ></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="project.link"
                    label="Link"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                    class="v-text-field--outlined fieldset"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" type="submit">
              {{ edit ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>


</template>
<style scoped>
 .v-text-field--outlined fieldset {
    border-color: currentColor !important
  } 
  </style>
