<template>
  <div>
    <!-- <v-toolbar dense flat dark color="deep-purple darken-2">
      <v-btn text fab @click.stop="drawer = !drawer"
        ><v-icon dark>mdi-menu</v-icon></v-btn
      >
      <v-spacer></v-spacer>
      <span class="h5 mt-2">{{ name }}</span>
      <v-spacer></v-spacer>

      <template v-if="!currentUser">
        <v-btn text id="button" to="/login">Login</v-btn>
      </template>
      <template v-else>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click.prevent="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar> -->

    <v-layout>
      <v-app-bar color="deep-purple darken-2" density="compact">
        <template v-slot:prepend>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </template>

        <template v-slot:append>
          <v-btn @click="logout" icon="mdi-logout"></v-btn>
        </template>
      </v-app-bar>
      <v-main></v-main>
    </v-layout>

    <v-navigation-drawer temporary v-model="drawer">
      <v-list-item>
        <!-- <v-list-item-avatar size="90">
          <v-img src="/img/img.jpg"></v-img>
        </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title>Edson Teliaken</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          id="button"
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
          :prepend-icon="item.icon"
          :title="item.title"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState, mapActions} from 'vuex';
export default {
  // name: "app-header",
  // props: {
  //   name: String,
  // },
  data: () => ({
    drawer: false,
    model: 1,
    group: null,
    items: [
      { title: "Home", icon: "mdi-account-tie", to: "/" },
      {
        title: "About Me",
        icon: "mdi-information-outline",
        to: "/adminaboutme",
      },
      { title: "Skills", icon: "mdi-lightbulb-on-outline", to: "/adminskills" },
      {
        title: "Work Experience",
        icon: "mdi-briefcase-variant-outline",
        to: "/adminexperience",
      },
      { title: "Projects", icon: "mdi-view-dashboard", to: "/adminprojects" },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    ...mapState(["isLoggedIn"]),
  },
  methods: {
    ...mapActions(["logout"]),
  },
};
</script>

<style scoped>
#button {
  text-decoration: none;
}
</style>
