import { createStore } from "vuex";
import router from './router';

export default createStore({
    state: {
        isLoggedIn: !!localStorage.getItem('token'),
        //isLogout: localStorage.removeItem('token')
    },
    mutations:{ //button
        LOGIN(state){
            state.isLoggedIn = true //on
        },
        LOGOUT(state){
            state.isLoggedIn = false //off
            localStorage.removeItem('token')
        }
    },

    actions:{
        login({ commit }){
            commit('LOGIN')
        },
        logout({commit, dispatch}) {
            commit('LOGOUT')
            dispatch('navigateToLogin')
        },
        navigateToLogin(){
            router.push({name: 'Home'});
     }
    }
})