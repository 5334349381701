<script>
import axios from "@/axios";
import AdminHeader from "./AdminHeader.vue";
import Swal from "sweetalert2";
export default {
  components: { AdminHeader },
  data: () => ({
    dialog: false,
    edit: false,
    loading: true,
    experience: {
      id: "",
      position: "",
      company: "",
      address: "",
      date: "",
    },
    experiences: [],

    headers: [
      { title: "Position", key: "position", align: "start" },
      { title: "Company", key: "company", align: "end" },
      { title: "Address", key: "address", align: "end" },
      { title: "Date", key: "date", align: "end" },
      { title: "Role", key: "role", align: "end", sortable: false },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],
  }),
  created() {
    this.fetchExperience();
  },
  methods: {
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios.delete(`/experience/${id}`).then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              });

              this.fetchExperience();
            });
          } catch (error) {
            console.error("An error ocurred:", error);
          }
        }
      });

      // this.$swal('Hello Vue world!!!');
    },
    async editExperience() {
      let payload = this.experience;
      let id = this.experience.id;
      try {
        await axios.put(`/experience/${id}`, payload, {});
        this.fetchExperience();
        this.resetForm();
        this.dialog = false;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
    editItem(experience) {
      this.edit = true;
      this.experience.id = experience.id;
      this.experience.position = experience.position;
      this.experience.company = experience.company;
      this.experience.address = experience.address;
      this.experience.date = experience.date;
      this.dialog = true;
    },
    resetForm() {
      this.experience.position = "";
      this.experience.company = "";
      this.experience.address = "";
      this.experience.date = "";
    },
    addData() {
      this.edit = false;
      this.resetForm();
      this.dialog = true;
    },
    async addExperience() {
      let payload = this.experience;
      try {
        await axios.post("/experience", payload, {});
        this.fetchExperience();
        this.resetForm();
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },

    async fetchExperience() {
      try {
        const response = await axios.get("/experiences");
        this.experiences = response.data;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
  },
};
</script>
<template>
  <AdminHeader />

  <v-card class="mx-auto" max-width="100%">
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-chip size="large" color="green" label>Experience</v-chip>
            <v-btn
              @click="addData()"
              class="ma-2"
              variant="text"
              icon="mdi-plus-box"
              color="blue-lighten-2"
            ></v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="name"
              placeholder="Placeholder"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-data-table :items="experiences" :headers="headers">
        <template v-slot:item.role="{ item }">
          <router-link :to="`/adminrole/${item.id}/${item.company}`" id="button"
            ><v-icon size="30px" color="green">mdi-plus</v-icon></router-link
          >
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon size="small" class="me-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item.id)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>

  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="1024">
      <v-form @submit.prevent="edit ? editExperience() : addExperience()">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ edit ? "Edit Data" : "Add Data" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="experience.position"
                    label="Position"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="experience.company"
                    label="Company"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="experience.address"
                    label="Address"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="experience.date"
                    label="Date"
                    variant="underlined"
                    prepend-icon="mdi-file-document-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" type="submit">
              {{ edit ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
