<script>
import axios from "@/axios";
import AdminHeader from "./AdminHeader.vue";
import Swal from "sweetalert2";
export default {
  components: { AdminHeader },
  data: () => ({
    dialog: false,
    edit: false,
    loading: true,
    role: {
      id: "",
      description: "",
    },
    roles: [],

    headers: [
      { title: "Description", key: "description", align: "start" },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],
  }),
  created() {
    this.fetchRole();
  },
  methods: {
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios.delete(`/role/${id}`).then((res) => {
              Swal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                icon: "success",
              });

              this.fetchRole();
            });
          } catch (error) {
            console.error("An error ocurred:", error);
          }
        }
      });
    },
    
    async editRole() {
      let payload = this.role;
      let id = this.role.id;
      try {
        await axios.put(`/role/${id}`, payload, {});
        this.fetchRole();
        this.resetForm();
        this.dialog = false;
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
    editItem(role) {
      this.edit = true;
      this.role.id = role.id;
      this.role.description = role.description;
      this.dialog = true;
    },
    resetForm() {
      this.role.description = "";

    },
    addData() {
      this.edit = false;
      this.resetForm();
      this.dialog = true;
    },
    async addRole() {

    //   let payload = new FormData();
    //   payload.append('experience_id', this.$route.params.id)
    //   payload.append('description', this.role.description)
      try {
        await axios.post("/role", {
            experience_id: this.$route.params.id,
            description: this.role.description
        });
        this.fetchRole();
        this.resetForm();
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },

    async fetchRole() {
      try {

        const response = await axios.get(`/filtered_roles/${this.$route.params.id}`);
        this.roles = response.data.roles;

        //this.experienceId = this.$route.params.id

       // console.log(this.experienceId)
      } catch (error) {
        console.error("An error occured while adding", error);
      }
    },
  },
};
</script>
<template>
  <AdminHeader />

  <v-card class="mx-auto" max-width="100%">
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-btn to="/adminexperience" class="ma-2" variant="text" icon="mdi-arrow-left-circle" color="blue-lighten-2" ></v-btn>
            <v-chip size="large" color="green" label>{{
              $route.params.company
            }}</v-chip>
            <v-btn
              @click="addData()"
              class="ma-2"
              variant="text"
              icon="mdi-plus-box"
              color="blue-lighten-2"
            ></v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="name"
              placeholder="Placeholder"
              variant="underlined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-data-table :items="roles" :headers="headers">

        <template v-slot:item.actions="{ item }">
          <v-icon size="small" class="me-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon size="small" @click="deleteItem(item.id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>

  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="1024">
      <v-form @submit.prevent="edit ? editRole() : addRole()">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ edit ? "Edit Data" : "Add Data" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="role.description"
                    label="Description"
                    variant="underlined"
                    auto-grow
                    prepend-icon="mdi-file-document-outline"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" type="submit">
              {{ edit ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
