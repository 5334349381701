<script>
import axios from '@/axios';
import Navbar from '../Navbar.vue';
  export default {
    components: {Navbar},
    data: () => ({
      name:'Experience',
      experiences:[],
    }),
    created(){
     this.fetchExperiences();
    },
    methods:{
      fetchExperiences(){
        axios.get('/show_experiences').then(res=>{
         this.experiences = res.data.experiences;
        })
      },
    },
  }
</script>
<template>
<v-app class="layout">
  <Navbar />
  <v-container>

    <div class=" mt-15 flex justify-center align-middle">
        <ol class="mt-10 relative border-s border-gray-200 dark:border-gray-700" >  

          <li class="mb-10 ms-6" v-for = "experience in experiences" :key="experience.id" >            
              <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                <svg class="w-4 h-4 text-blue-800 dark:blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.4l1.4.7a7.7 7.7 0 0 0 .7.3 21 21 0 0 0 16.4-.3l1.5-.7V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5c0-.6-.4-1-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.4 7.9.6-.3V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.4l.6.3a10 10 0 0 0 .7.3 23 23 0 0 0 18-.3h.1L21 13l.4.9ZM12 10a1 1 0 1 0 0 2 1 1 0 1 0 0-2Z" clip-rule="evenodd"/>
                </svg>
              </span>

                <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">{{experience.position}}</h3>
                  <span class=" flex">
                    <h5 class="mb-1 text-lg font-semibold text-blue-900 dark:text-white">{{experience.company}}</h5>&nbsp;| &nbsp;
                    <h5 class="mb-1 text-lg font-normal text-blue-900 dark:text-white">{{experience.address}}</h5>
                  </span>
              
              <time class="block mb-2 text-md font-normal leading-none text-red-400 dark:text-gray-500">{{experience.date}}</time>
             
              <template v-for="role in experience.roles">

                <div class="flex  text-gray-500  dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
                  <i id="fa-icons" class=" mt-1 fa-regular fa-circle-dot"></i>&nbsp;
  
              <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{{ role.description }}</p>
            </div>
                
            </template>

          </li>
       
        </ol>
      
      </div>
  </v-container>
</v-app>
</template>
<style scoped>
#fa-icons {
  font-size: 15px;
  color: green;
}
.layout{
  background-color: #edf4f8;
  font-family: 'Rubik', sans-serif;
}
</style>