import { createRouter, createWebHistory } from "vue-router";

import Login from './components/Login.vue';
import Register from './components/Register.vue';
import Home from './components/Home.vue';
import AboutMe from './components/Front-End/AboutMe.vue';
import WorkExperience from './components/Front-End/WorkExperience.vue';
import Projects from './components/Front-End/Projects.vue';

import AdminHeader from './components/Back-End/AdminHeader.vue';
import AdminAboutMe from './components/Back-End/AdminAboutMe.vue';
import AdminExperience from './components/Back-End/AdminExperience.vue';
import AdminSkills from './components/Back-End/AdminSkills.vue';
import AdminProjects from './components/Back-End/AdminProjects.vue';
import AdminRole from './components/Back-End/AdminRole.vue';
import AdminFramework from './components/Back-End/AdminFramework.vue';


const routes = [
    {
        path:'/',
        name:'Home',
        component: Home,
        meta: {public: true} //Mark this router as public
    },
    {
        path:'/aboutme',
        name:'AboutMe',
        component: AboutMe,
        meta: {public: true} //Mark this router as public
    },
    {
        path:'/experience',
        name:'WorkExperience',
        component: WorkExperience,
        meta: {public: true} //Mark this router as public
    },
    {
        path:'/projects',
        name:'Projects',
        component: Projects,
        meta: {public: true} //Mark this router as public
    },

    {
        path:'/adminheader',
        name:'AdminHeader',
        component: AdminHeader,
        meta: {public: false} //Mark this router as public
    },
    {
        path:'/adminaboutme',
        name:'AdminAboutMe',
        component: AdminAboutMe,
        meta: {public: false} //Mark this router as public
    },
    {
        path:'/adminexperience',
        name:'AdminExperience',
        component: AdminExperience,
        meta: {public: false} //Mark this router as public
    },
    {
        path:'/adminskills',
        name:'AdminSkills',
        component: AdminSkills,
        meta: {public: false} //Mark this router as public
    },
    {
        path:'/adminprojects',
        name:'AdminProjects',
        component: AdminProjects,
        meta: {public: false} //Mark this router as public
    },
    //linked to AdminExperience
    { 
        path:'/adminrole/:id/:company',
        name:'AdminRole',
        component: AdminRole,
        meta: {public: false} //Mark this router as public
    },
    //linked to AdminProject
    { 
        path:'/adminframework/:id/:title',
        name:'AdminFramework',
        component: AdminFramework,
        meta: {public: false} //Mark this router as public
    },
    {
        path:'/login',
        name:'Login',
        component: Login,
        meta: {public: true} //Mark this router as public
    },
    {
        path:'/Register',
        name:'Register',
        component: Register,
        meta: {public: true} //Mark this router as public
    },
    // {
    //     path:'/add-product',
    //     name:'AddProduct',
    //     component: AddProduct,
    //     meta: {public: false} //Mark this router as private
    // },
    // {
    //     path:'/edit-product/:id',
    //     name:'EditProduct',
    //     component: EditProduct,
    //     meta: {public: false} //Mark this router as private
    // },
    // {
    //     path:'/product/:id',
    //     name:'ProductDetails',
    //     component: ProductDetails,
    //     meta: {public: false} //Mark this router as private
    // },
    // {
    //     path:'/',
    //     name:'ProductList',
    //     component: ProductList,
    //     meta: {public: false} //Mark this router as private
    //},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem('token');

    if(!to.meta.public && !isAuthenticated){
        next({name: 'Login'});
    } else {
        next();
    }
});

export default router;